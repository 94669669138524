<template>
  <section class="oms">
      <router-view></router-view>
  </section>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Routes from './OMS/routes.js';
//import $ from 'jquery';
import vClickOutside from 'v-click-outside';
import VueFlatPickr from 'vue-flatpickr-component';
import VueMeta from 'vue-meta';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import VueCountryCode from "vue-country-code";
import VTooltip from 'v-tooltip';
import Treeselect from '@riophae/vue-treeselect'

// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

Vue.use(VTooltip);
Vue.use(VueMeta)
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(vClickOutside);
Vue.use(VueFlatPickr);
Vue.use(VueCountryCode);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Treeselect)
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// import VueCarousel from 'vue-carousel';
// Vue.use(VueCarousel);

const store=new Vuex.Store({
  state: {
      base_url:'',
      api_url:'',
      api_token:'',
      //api_key:'',
      site:{
        auth_user:{
          name: 'Unknown',
          avatar: 'https://place-hold.it/400x400?text=Avatar&italic=true&fontsize=30'
        },
        company:{
          id: null,
          name: 'Loading...'
        },
        countries:[],
        permission_disable: false,
        user_permissions:null
      },
      dropdowns: {},
      storeLoadComplete: false,
  },
  mutations: {
    setapiUrl: function(state, value){
      state.base_url=value;
    },
    setApiToken: function(state, value){
      state.api_token=value;
    },
    siteInfo: function(state, args){
      state.site[args.key]=args.val;
    },
    setDropdowns: function(state, value){
      state.dropdowns = value;
      // state.storeLoadComplete = !state.storeLoadComplete;
    },
  }
});

const router=new VueRouter({
  // mode: 'history',
  routes: Routes,
});

router.beforeEach((to, from, next)=>{

  var api_token=localStorage.getItem('api_token');

  if(!api_token){
    api_token = store.state.api_token;;
  }
  const permission = to.meta.permission;

  if(!api_token && !to.meta.requiredAuth){
    next()
  }else{

    if(to.meta.requiredAuth ){
     
      if(!api_token && to.name!=='Login') {
        sessionStorage.setItem('redirectPath', to.path);
        next({ name: 'Login' })
      }else if(to.name=='Login' && api_token) {
        next('/dashboard')
      }else {
        next();
        // console.log(permission)
        // if(permission == ''){ 
        //   next();
        // }else{
        //   const permission_all = store.state.site.user_permissions;
        //   if(permission_all === null){
        //     axiosInstance.get('/api/v1/client/ajax/check_route_permission',{
        //       params:{
        //         permission: permission
        //       }
        //     })
        //       .then((response) => {
        //             if(!response.data.data.permitted){
        //               next('/404');
        //             }else{
        //               next();
        //             }
        //         })
        //         .catch((error) => {
        //           next('/404');
        //         });
        //     next();
        //   }else if(permission_all.includes(permission)){
        //     next();
        //   }else next('/404');

        // }   
      }
        
    }else{ 
      next()
    };

  }



  /* if(to.name=='ResetPassword' || to.name=='ForgotPassword' || to.name=='' || to.name=='SignUp'){
    next();
  }else if(to.name!=='Login' && !api_token) {
    sessionStorage.setItem('redirectPath', to.path);
    next({ name: 'Login' })
  } else if(to.name=='Login' && api_token) {
    next('/dashboard')
  } else if(!to.meta.requiredAuth) {
    next();
  }
  else next(); */

});

export default {
  store,
	router,
	name: 'OMS',
	props: ['base_url'],
  metaInfo: {
    title: 'OMS',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'OMS, Office management system.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0' }
    ]
  },
	created: async function(){

    var ref=this;

    ref.$store.commit('setapiUrl', ref.base_url);

    let api_token=localStorage.getItem('api_token');

    if(api_token){

      this.$store.commit('setApiToken', api_token);
      this.ajax_setup();
      
    }

    await this.getAuthUserInfo();

	},
  mounted: function(){

    this.getCommonDropdownList();
    // this.getAuthUserInfo();


  },
  methods: {
    getCommonDropdownList: async function () {

      let ref = this;
      let url = `${this.base_url}/api/v1/get_common_dropdown_list`;
      let jq = ref.jq();

      try {
          let res = await jq.get(url);
          this.$store.commit('setDropdowns', res.data);
      } catch (error) {
          this.alert(ref.err_msg(error), 'error')
      }
    },

  },watch:{
    $route(to, from){
      this.check_route_permission(to);
      
    }
  } 
}

</script>


<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~flatpickr/dist/flatpickr.min.css";
@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
/* @import "../static/frest/dist/css/theme-default.css";  */
@import "../static/frest/assets/vendor/css/rtl/core.css";
@import "../static/frest/assets/vendor/css/rtl/theme-default.css";
@import "../static/frest/assets/css/demo.css";
/* @import "../static/frest/assets/vendor/fonts/boxicons.css"; */
/* @import "https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"; */
@import "~boxicons/css/boxicons.min.css";

/* @import "../static/frest/assets/vendor/fonts/fontawesome.css"; */

@import "~fontawesome-4.7/css/font-awesome.min.css";

@import "../static/frest/assets/vendor/fonts/flag-icons.css";
@import "../static/waitMe/waitMe.min.css";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/sunset.css";
@import "../css/style.css";
/* @import "../css/custom-flatpicker.css"; */
/* @import "~flatpickr/dist/themes/material_green.css"; */
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}


.flatpickr-prev-month, .flatpickr-next-month {
    border: unset !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.vue-country-select .dropdown-list {
  z-index: 10 !important;
}
.vue-country-select .dropdown {
    padding: 2px 4px 2px 5px !important;
}
.vue-treeselect__control{
    height: 26px !important;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value {
    line-height: 23px !important;
    font-size: 14px !important;
}
.dropdown-item {
  padding: 0 0 8px 9px !important;
}
.pagination .page-item.active .page-link {
    border-radius: 0.25rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0.66rem !important;
    padding-bottom: 0.66rem !important;
}
.b-pagination-pills .page-item .page-link {
    border-radius: none !important;
}
/* .flatpickr-calendar.inline {
    width: 100% !important;
}
.flatpickr-weekdays {
    width: 211px !important;
} */
.flatpickr-calendar.inline {
    min-width: 100% !important;
}
.flatpickr-days{
  min-width: 100% !important;
}
.flatpickr-rContainer{
  min-width: 100% !important;
}
.dayContainer{
  min-width: 100% !important;
}

.hand{ cursor: pointer !important; }
.text-bold{ font-weight: bold; }
.text-bold-300{ font-weight: 300; }
.text-bold-400{ font-weight: 400; }
.text-bold-500{ font-weight: 500; }
.text-bold-600{ font-weight: 600; }
.text-bold-700{ font-weight: 700; }
.text-bold-800{ font-weight: 800; }
.text-bold-900{ font-weight: 900; }

.font-size-10 { font-size: 10px !important; }
.font-size-11 { font-size: 11px !important; }
.font-size-12 { font-size: 10px !important; }
.font-size-13 { font-size: 13px !important; }
.font-size-14 { font-size: 14px !important; }
.font-size-15 { font-size: 15px !important; }
.font-size-16 { font-size: 16px !important; }
.font-size-18 { font-size: 18px !important; }
.font-size-20 { font-size: 20px !important; }
.font-size-22 { font-size: 22px !important; }
.font-size-24 { font-size: 24px !important; }
.font-size-26 { font-size: 26px !important; }
.font-size-28 { font-size: 28px !important; }
.font-size-30 { font-size: 30px !important; }
.font-size-32 { font-size: 32px !important; }
.font-size-34 { font-size: 34px !important; }
.font-size-36 { font-size: 36px !important; }
.font-size-38 { font-size: 38px !important; }
.m-15 { margin: -4.85px !important; }
.m-25 { margin: 0.25px !important; }
.m-50 { margin: 0.50px !important; }
.m-75 { margin: 0.75px !important; }
.ml-15 { margin-left: -4.85px !important; }
.ml-25 { margin-left: 0.25px !important; }
.ml-50 { margin-left: 0.50px !important; }
.ml-75 { margin-left: 0.75px !important; }
.mrp-15 { margin-right: 15px !important; }
.mr-15 { margin-right: -4.85px !important; }
.mr-25 { margin-right: 0.25px !important; }
.mr-50 { margin-right: 0.50px !important; }
.mr-75 { margin-right: 0.75px !important; }
.underline { text-decoration: underline !important; }
.underline-2 { text-decoration: underline !important; text-decoration-thickness: 2px !important; }
.underline-3 { text-decoration: underline !important; text-decoration-thickness: 3px !important; }
.underline-4 { text-decoration: underline !important; text-decoration-thickness: 4px !important; }
.underline-5 { text-decoration: underline !important; text-decoration-thickness: 5px !important; }

input.form-control[type=file] {
    padding: 2px 0px 5px 10px !important;
    font-size: 0.8rem !important;
    height: 32px !important;
    line-height: 2!important;
}
/* input.form-control {
    padding: 0 0 0 8px !important;
    font-size: 0.8rem !important;
    height: 26px !important;
    line-height: 2 !important;
} */
.input-group .input-group-merge span.input-group-text {
  /* height: 26px !important; */
  height: 31px !important;
}
/* .row-height {
    height: 56px !important;
} */

/* .card-header {
    padding-bottom: 0px !important;
    padding-top: 10px !important;
    height: 35px !important;
} */

/* .tab-row-height {
    height: 40px !important;
} */

/* .card-body {
    padding-bottom: 10px !important;
} */

label.form-label{
  font-size: 12px;
  /*font-weight: 600;*/
  margin-bottom: unset;
}

.text-primary-link { color: #17a2b8 !important; font-weight: 500; }
.text-primary-link:hover { color: #23cbe5 !important; }

.border-success {
  border: 1px solid green !important;
}
.text-dark-blue {
    color: #1b2898 !important;
}

.text-primary {
    color: #13426C!important
}
.width-100 { width: 100% !important; }
.width-90 { width: 90% !important; }
.width-85 { width: 85% !important; }
.width-75 { width: 75% !important; }
.width-50 { width: 50% !important; }
.width-25 { width: 25% !important; }
.height-100 { height: 100% !important; }
.height-75 { height: 75% !important; }
.height-50 { height: 50% !important; }
.height-25 { height: 25% !important; }
.delete-icon {
    color: #f8192e !important;
    cursor: pointer;
}
.delete-icon:hover {
    color: #c61628 !important;
}
.b-icon.bi {
    color: white;
    background: red;
    border-radius: 100%;
}
.b-sidebar > .b-sidebar-header {
    display: inline;
}
label {
    display: inline-block;
    margin-bottom: 0.25rem;
}
.form-control {
    font-size: 0.75rem!important;
}
.vue-treeselect__control {
    padding-left: 5px;
    padding-right: 5px;
    padding: 0.3rem 0.735rem 0.5rem 0.735rem!important;
    font-size: 0.75rem!important;
    display: table;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #d4d8dd!important;
    border-radius: 0.25rem!important;
    background: #fff;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value {
    line-height: 23px !important;
    font-size: 0.75rem!important;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value {
    padding-left: 0px!important;
}
.vue-treeselect__input{
    margin-left: -5px!important;
}
.btn-xs-custom {
    padding: 0.35rem 0.5rem
}
.btn-sm-custom{
    padding: 0.35rem 1rem !important;
}
html:not([dir=rtl]) .menu-horizontal .menu-sub .menu-sub .menu-item .menu-link {
    padding-left: 0.5rem!important;
}
.menu-horizontal .menu-sub .menu-link {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    border-radius: 0 !important;
}

/* Date flatpickr */
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
    border-color: #13426C !important;
    background: #13426C !important;
    color: #fff;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background:  #13426C !important;
    border-color:  #13426C !important;
    color: #fff !important;
}
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
    color: #fff !important;
    background: #13426C !important;
    border-color: #13426C !important;
}
.flatpickr-day.today {
    border-color: #13426C !important;
}
.flatpickr-prev-month svg, .flatpickr-next-month svg {
    fill: #13426C !important;;
    stroke: #13426C !important;
}

.flatpickr-months.flatpickr-prev-month.flatpickr-monthDropdown-month:hover{
  background: #13426C!important;
  font-weight: 400!important;
}

.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
    color: #fff !important;
    background: #13426C!important;
    border-color: #13426C!important;
}

/* .flatpickr-day.selected,.flatpickr-day.startRange,.flatpickr-day.endRange,.flatpickr-day.selected.inRange,.flatpickr-day.startRange.inRange,.flatpickr-day.endRange.inRange,.flatpickr-day.selected:focus,.flatpickr-day.startRange:focus,.flatpickr-day.endRange:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange:hover,.flatpickr-day.endRange:hover,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.endRange.nextMonthDay{
  background:#13426C!important;
  -webkit-box-shadow:none;
  box-shadow:none;
  color:#fff;
  border-color:#13426C!important;
} */
/* Date flatpickr */

span.flatpickr-day.selected{
  border-color:#13426C!important;
  background: #13426C!important;
}
/* button */
.btn-primary{
    color:#fff;
    background-color: #13426C!important;
    border-color: #13426C!important;
    box-shadow:unset;
}
.btn-outline-primary{
    color: #13426C!important;
    border-color: #13426C!important;
    background: transparent;
}
.btn-outline-primary:hover {
    color: #fff!important;
    background-color: #13426C!important;
    border-color: #13426C!important;
}
.btn-transparent {
    color: #000!important;
    background-color: #69809a00!important;
    border-color: #cbcbcb!important;
    box-shadow: 0 0.125rem 0.25rem rgba(147, 158, 170, 0.4)!important;

}
.btn-sm-squre {
  padding: 3px 10px !important;
  font-size: 0.813rem !important;
  border-radius: 0.1875rem !important;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary:hover{
    color:#fff !important;
    background-color: #13426C!important;
    border-color: #13426C!important;
    box-shadow:unset;
}
.btn-check:focus+.shepherd-element .shepherd-button:not(:disabled).btn-primary,.shepherd-element .shepherd-button:not(:disabled).btn-primary:focus,.shepherd-element .shepherd-button:not(:disabled).btn-primary.focus{
    color:#fff;
    background-color: #13426C!important;
    border-color: #13426C!important;
    box-shadow:unset;
}
.btn-check:checked+.shepherd-element .shepherd-button:not(:disabled).btn-primary,.btn-check:active+.shepherd-element .shepherd-button:not(:disabled).btn-primary,.shepherd-element .shepherd-button:not(:disabled).btn-primary:active,.shepherd-element .shepherd-button:not(:disabled).btn-primary.active,.shepherd-element .shepherd-button:not(:disabled).btn-primary.show.dropdown-toggle,.show>.shepherd-element .shepherd-button:not(:disabled).btn-primary.dropdown-toggle{
    color:#fff !important;
    background-color: #13426C!important;
    border-color: #13426C!important;
}
.btn-check:checked+.shepherd-element .shepherd-button:not(:disabled).btn-primary:focus,.btn-check:active+.shepherd-element .shepherd-button:not(:disabled).btn-primary:focus,.shepherd-element .shepherd-button:not(:disabled).btn-primary:active:focus,.shepherd-element .shepherd-button:not(:disabled).btn-primary.active:focus,.shepherd-element .shepherd-button:not(:disabled).btn-primary.show.dropdown-toggle:focus,.show>.shepherd-element .shepherd-button:not(:disabled).btn-primary.dropdown-toggle:focus{
    box-shadow:unset;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary.disabled,.shepherd-element .shepherd-button:not(:disabled).btn-primary:disabled{
    color:#fff !important;
    background-color: #13426C!important;
    border-color: #13426C!important;
    box-shadow:unset;
}

/* button  */
/* treeselect */
.vue-treeselect--single .vue-treeselect__option--selected {
    background: #13426C!important;
    font-weight: 400!important;

}
.vue-treeselect__option--highlight {
    background: #13426C!important;
}
.vue-treeselect__option--highlight .vue-treeselect__label-container .vue-treeselect__label{
  color: white!important;
}
.vue-treeselect--single .vue-treeselect__option--selected .vue-treeselect__label-container .vue-treeselect__label{
  color: white!important;
}
/* treeselect */
/* pagination */
.page-item:not(.first, .last, .next, .prev, .previous) {
    background-color: #d8f7d4;
}
.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus, .pagination li.active > a:not(.page-link), .pagination li.active > a:not(.page-link):hover, .pagination li.active > a:not(.page-link):focus {
    background-color: #13426C!important;
    border-color: #13426C!important;
    color: #fff;
    box-shadow: unset;
}
/* pagination */
.bg-white {
    border-radius: 5px;
}
.bg-light-success{
  background: #A1E2C0 !important;
  color: #2EC873 !important
}
.bg-light-danger{
  background: #F4E2E5 !important;
  color: #EA5455 !important
}
.bg-light-warning{
  background: #FFCB9A !important;
  color: #FFA148 !important
}
.bg-light-info{
  background: #78E2F0 !important;
  color: #36D8EC !important
}
.bg-light-secondary{
  background: #F3F2F7 !important;
  color: #6E6B7B !important
}
.card-body {
    flex: 1 1 auto!important;
    padding: 1rem 1rem!important;
}
.margin-bottom-unset{
  margin-bottom:0.25rem!important;
}
ol, ul, dl {
    margin-bottom: .25rem!important;
}
.margin-top-minus-5px{
  margin-top:-5px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .fc .fc-toolbar-title {
    font-size: 1em!important;
  }
  .fc .fc-button .fc-icon {
    font-size: .75em!important;
  }
  .fc .fc-button {
    font-size: .8em!important;
    padding: 0.2em 0.65em!important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

.d-font-size{
  font-size: 13px;
  color: rgb(65, 65, 65);
}

.d-link-hover:hover{
  color: rgb(123, 30, 194);
}

.bg-f1f1{
  background-color: #f1f1f1;
}

.text-f1f1{
  color: #f1f1f1;
}

label {
    font-size: 12px;
    color: #000;
}
label span {
    font-size: 12px;
    color: #000;
}
body{
  color: #000 !important;
}
.table>thead>tr>th {
    /* background-color: rgb(224, 224, 224); 
    background-color: #F3F2F7;
     background-color: rgb(244, 244, 244); 
    color: #000 !important;*/
    background-color: #13426C!important;
    color:#ffffff;
    text-transform: capitalize!important;
}
/* .table>tbody>tr:nth-child(odd)>td{
    background-color: rgb(244, 244, 244);
} */
/* .table-striped>tbody>tr:nth-child(odd)>td{
    background-color: rgb(244, 244, 244);
} */
 .table>thead>tr>th:first-child{
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
 }
 .table>thead>tr>th:last-child{
  border-top-right-radius: 10px!important;
  border-bottom-right-radius: 10px!important;
}
.table>tbody>tr>td,
.table>tbody>tr>th {
    color: #000 !important;
}
.table>tbody>tr>th {
  color: #fff !important;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: #000000 !important;
}
.nav-link {
    color: #000000 !important;
}
/* .btn {
    color: #000000 !important;
} */
.bg-label-success {
    background-color: #E5F8ED !important;
    color: #28C76F !important;
}
/* .btn-primary {
    color: #fff !important;
} */

/* .btn {
    color: #fff !important;
} */
.btn-success {
    background-color: #28C76F !important;
}
.badge .badge-success {
    background-color: #28C76F !important;
}
.border-radius-1 { border-radius: 15px !important;}
.border-radius-2 { border-radius: 25px !important;}
.border-radius-3 { border-radius: 50px !important;}
.border-radius-4 { border-radius: 75px !important;}
.border-radius-5 { border-radius: 100px !important;}
.icon-hover{
  display: inline;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 50%;
  cursor: pointer;
  border-radius: 100px !important;
  /* font-size: 12px; */
}

.icon-hover:hover{
  background-color: #d1d1d1;
}
.btn-small{
    padding: 5px !important;
    font-size: 10px !important;
    line-height: .5 !important;
}
/* .swal2-container swal2-center swal2-backdrop-show */
.swal2-container {
  z-index: 9999 !important;
}
.form-control {
  font-weight: 500 !important;
  color:#484848 !important;
}

.text-body {
    color: rgb(65, 65, 65)!important;
    font-size: 12px !important;
}

.task-avater{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 0.5px solid #d781ff;
  margin-right: -5px;
}
body {
  background: linear-gradient(43deg, rgb(248 247 255) 0%, rgb(223 189 189) 72%, rgb(10 166 157) 100%) !important;
}
.layout-horizontal .layout-navbar {
    /* background: linear-gradient(36deg, rgb(248 247 255) 0%, rgb(223 189 189) 72%, rgb(10 166 157) 100%) !important; */
    background: linear-gradient(43deg, rgb(248 247 255) 0%, rgb(223 189 189) 29%, rgb(10 166 157) 100%) !important;
}
/* .text-gradient {
  font-weight: 800;
  background: -webkit-linear-gradient(#008c83, #95005d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #b921210a;
} */
.text-gradient {
  /*background: -webkit-linear-gradient(57deg, #00bd6f, #000da4 80%); */
  background: -webkit-linear-gradient(57deg,  #13426C, #000da4 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-label-primary {
    color:  #1b2898  !important;
    border-color: transparent !important;
    background: #e2f9df !important;
}
.btn-label-primary:hover { background:  #1b2898  !important; color: #FFF !important}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #1b2898  !important;
    background-color:  #1b2898  !important;
}
.btn-label-blue {
  color: #1b2898  !important;
  border-color: transparent !important;
  background: #e5e6ff !important;
}
.btn-label-blue:hover { background: #1b2898  !important; color: #FFF !important}
.text-decoration {
    text-decoration: none !important;
}
.form-select { padding: 5px !important }

.modal-backdrop {
    background-color: #263c5573 !important;
    transition: all .3s;
}

.bg-blue{
  background-color: #006BCE;
}
.bg-blue:hover{
  background-color:#13426C!important;
  color: #f9f9f9;
}
.btn-label-secondary,.bg-label-secondary, .btn-primary,.swal2-confirm{
  background: #13426C!important;
  color:#ffffff!important;
}
.btn-primary,.swal2-confirm{
  border-color:#13426C!important;
}
.b-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
#flat-picker-custom,.flatpickr-input{
    background: #fff!important;
    border: none;
}
input,.vue-treeselect__control,textarea {
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem rgb(19 66 108 / 11%);
}
</style>
